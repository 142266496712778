angular.module("fonderieComponent").service("stripe", function () {
  console.log(window.location.protocol !== "https");
  if (!window.fdGlobals && !window.fdGlobals.stripePublicKey) {
    throw "fdGlobals.stripePublicKey missing. ";
  }

  if (window.location.protocol !== "https") {
    return;
  }
  return Stripe(window.fdGlobals.stripePublicKey);
});
