angular.module("fonderieComponent").directive("shipping", [
  "storeService",
  function (Store) {
    return {
      restrict: "E",
      templateUrl: "js/partials/shipping.html",

      scope: {},
      link: function ($scope, $element, $attr) {
        var session = Store.getSession();

        if (session.shippingMethod) {
          $scope.shippingMethod = session.shippingMethod;
        } else {
          $scope.shippingMethod = {
            key: "mail",
            shippingRateId: window.fdGlobals.mailDeliveryShippingRate,
          };
        }

        $scope.shippingMethods = Store.shippingMethods;

        $scope.selectShippingMethod = function (key) {
          $scope.shippingMethod = $scope.shippingMethods.find(function (
            shippingMethod
          ) {
            return shippingMethod.key == key;
          });

          Store.setSession({
            shippingMethod: $scope.shippingMethod,
          });
        };
      },
    };
  },
]);
