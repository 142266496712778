var app = angular.module("fonderieComponent", ["pascalprecht.translate"]);

app.config(function ($sceDelegateProvider) {
  $sceDelegateProvider.resourceUrlWhitelist(["https://www.youtube.com/**"]);
});

app.config(function ($translateProvider) {
  $translateProvider.translations("en", {
    CART_TITLE: "Cart",
    CART_EMPTY: "Your cart is empty.",
    CHECKOUT_BUTTON: "Checkout",
    ADD_TO_CART: "Add to cart",

    SHIPPING: {
      TITLE: "Choose your shipping method",
      PICKUP_LABEL: "Pickup on site",
      MAIL_DELIVERY_LABEL: "Canada Post",
    },

    COOKIE_OPT_IN: {
      ACCEPT: "Accept",
      REJECT: "Reject",
      TITLE: "This site uses cookies",
      TEXT: "We use cookies to ensure our site functions properly and to improve your experience. Basic cookies are essential for the website's core functionality, while third-party cookies help us analyze site usage. By clicking 'Accept' you agree to let our third-parties using cookies for analytical purpose.",
      LINK: `  For more information you can consult our <a href="${window.fdGlobals.privacyPolicyUrl}">Privacy Policy</a>`,
    },
  });

  $translateProvider.translations("fr", {
    CART_TITLE: "Panier",
    CART_EMPTY: "Votre panier est vide.",
    CHECKOUT_BUTTON: "Payer",
    ADD_TO_CART: "Ajouter au panier",

    SHIPPING: {
      TITLE: "Sélectionnez votre méthode de livraison",
      PICKUP_LABEL: "Récuperer sur place",
      MAIL_DELIVERY_LABEL: "Poste Canada",
    },

    COOKIE_OPT_IN: {
      ACCEPT: "Accepter",
      REJECT: "Refuser",
      TITLE: "Ce site utilise des cookies",
      TEXT: "Nous utilisons des cookies pour garantir le fonctionnement de notre site. Certains cookies de base sont essentiels aux fonctionnalités de notre site, par contre certains cookies tiers nous aident à analyser l'utilisation du site. En cliquant sur 'Accepter', vous acceptez que des parties tiers utilisent des cookies à des fins analytiques.",
      LINK: `Pour plus dinformation consultez notre <a href="${window.fdGlobals.privacyPolicyUrl}">politique de confidentialité</a>`,
    },
  });
  $translateProvider.preferredLanguage(fdGlobals.lang);
});

angular.element(function () {
  angular.bootstrap(document.body, ["fonderieComponent"]);
});
