angular.module("fonderieComponent").directive("galleryItem", [
  "$timeout",
  function ($timeout) {
    return {
      restrict: "A",

      link: function ($scope, $element, $attr) {
        $scope.$on("gallery-fullscreen", function (data) {
          if ($attr.caption == undefined) {
            return;
          }

          if (data.targetScope.fullscreen) {
            $element.append("<div class='caption'>" + $attr.caption + "</div>");
          } else {
            $element.find(".caption").remove();
          }
        });
      },
    };
  },
]);
