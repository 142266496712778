angular.module("fonderieComponent").factory("optInCookieService", [
  "$window",
  "$http",
  function ($window, $http) {
    class OptInCookie extends EventTarget {
      constructor() {
        super();

        var self = this;

        this.optInCookies = function () {
          return $http({
            method: "POST",
            url: window.fdGlobals.cookieOptInUrl,
            data: {
              enableOptInCookie: true,
            },
          });
        };

        this.optOutCookies = function () {
          return $http({
            method: "POST",
            url: window.fdGlobals.cookieOptInUrl,
            data: { disableOptInCookie: true },
          });
        };
      }
    }

    return new OptInCookie();
  },
]);
