angular.module("fonderieComponent").filter("trusted", function ($sce) {
  return function (ss) {
    return $sce.trustAsHtml(ss);
  };
});

angular.module("fonderieComponent").directive("optInCookie", [
  "$compile",
  "optInCookieService",
  function ($compile, optInCookieService) {
    return {
      restrict: "E",
      templateUrl: "js/partials/opt-in-cookie.html",

      scope: {},

      link: function ($scope, $element, $attr) {
        var optInAsked = window.fdGlobals.cookieEnabled;
        console.log(optInAsked);

        if (!optInAsked) {
          $scope.showOptInBanner = true;
        } else {
          $scope.showOptInBanner = false;
        }

        $scope.agree = () => {
          optInCookieService.optInCookies();
          // localStorage.setItem("optInAsked", "true");

          $scope.showOptInBanner = false;
        };

        $scope.reject = () => {
          optInCookieService.optOutCookies();
          // localStorage.setItem("optInAsked", "true");
          $scope.showOptInBanner = false;
        };
      },
    };
  },
]);
