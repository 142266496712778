angular.module("fonderieComponent").factory("storeService", [
  "$window",
  "$http",
  "stripe",
  function ($window, $http, stripe) {
    class Store extends EventTarget {
      constructor() {
        super();

        var self = this;

        this.shippingMethods = [
          {
            key: "mail",
            shippingRateId: window.fdGlobals.mailDeliveryShippingRate,
          },
          {
            key: "pickup",
            shippingRateId: window.fdGlobals.pickupShippingRate,
          },
        ];

        this.session;

        this.showCart = function () {
          this.dispatchEvent(new Event("showCart"));
        };

        this.hideCart = function () {
          this.dispatchEvent(new Event("hideCart"));
        };

        this.checkoutSessionCheckInitialized = false;

        this.initCheckoutSessionCheck = function () {
          if (
            !this.checkoutSessionCheckInitialized &&
            self.session.checkout &&
            self.session.checkout.id
          ) {
            this.getSessionCheckout$().then((checkoutSession) => {
              if (checkoutSession.payment_status != "unpaid") {
                self.clearCart();
                self.clearSession();
                self.dispatchEvent(new Event("cartUpdated"));
              }
            });

            window.setInterval(() => {
              this.getSessionCheckout$().then((checkoutSession) => {
                if (checkoutSession.payment_status != "unpaid") {
                  self.clearCart();
                  self.clearSession();
                  self.dispatchEvent(new Event("cartUpdated"));
                }
              });
            }, 60000);
            this.checkoutSessionCheckInitialized = true;
          }
        };

        this.initSession = function () {
          if (!this.session) {
            var session = $window.localStorage.getItem("fdSession");

            if (session) {
              self.session = JSON.parse(session);
            } else {
              self.session = {
                hasShippable: false,
                shippingMethod: this.shippingMethods[0],
              };
            }

            this.initCheckoutSessionCheck();
          }
        };

        this.getSession = function () {
          return this.session;
        };

        this.getSessionCheckout$ = function () {
          if (this.session.checkout && this.session.checkout.id) {
            return $http({
              method: "GET",
              url: "/api-checkout",
              params: { id: this.session.checkout.id },
            }).then(function (response) {
              return response.data;
            });
          }
        };

        this.setSession = function (payload) {
          Object.assign(this.session, payload);

          $window.localStorage.setItem(
            "fdSession",
            JSON.stringify(this.session)
          );
        };

        this.updateProduct$ = function (payload) {
          return $http({
            method: "POST",
            url: "/api-product",
            data: payload,
          });
        };

        this.getProductByStripePriceId$ = function (priceId) {
          return $http({
            method: "GET",
            url: "/api-product",
            params: { priceId: priceId },
          });
        };

        this.checkout$ = function () {
          var session = {};

          var line_items = this.items.map(function (item) {
            var quantiy = item.quantity ? item.quantity : 1;
            return {
              // tax_rates :  [window.fdGlobals.taxRateId],
              quantity: quantiy,
              price: item.price.id,
            };
          });

          //Set shipping rate
          if (this.session.hasShippable) {
            session.shipping_rates = [
              this.session.shippingMethod.shippingRateId,
            ];
          }

          session.line_items = line_items;

          return $http({
            data: session,
            method: "POST",
            url: "/api-checkout",
          }).then(function (response) {
            self.setSession({ checkout: response.data });
            self.initCheckoutSessionCheck();

            return stripe.redirectToCheckout({ sessionId: response.data.id });
          });
        };

        this.addItemByPriceId = function (productItem) {
          if (!this.items) {
            this.items = [];
          }

          var cartItem = this.items.find(function (cartItem) {
            return productItem.stripePriceId === cartItem.price.id;
          });

          //Already exists incrementing the cart.
          if (cartItem) {
            cartItem.quantity =
              Number(productItem.quantity) + Number(cartItem.quantity);
            return;
          }

          if (!productItem || !productItem.stripePriceId) {
            return;
          }

          var product$ = this.getProductByStripePriceId$(
            productItem.stripePriceId
          );
          product$.then(function (response) {
            var item = response.data;

            if (item.shippable) {
              self.setSession({
                hasShippable: true,
              });
            }

            item.quantity = productItem.quantity;

            self.items.push(item);
            $window.localStorage.setItem(
              "fdCartItems",
              JSON.stringify(self.items)
            );

            self.dispatchEvent(new Event("cartUpdated"));
          });

          try {
            $window.localStorage.setItem(
              "fdCartItems",
              JSON.stringify(this.items)
            );
          } catch (e) {
            return e;
          }
        };

        this.removeItem = function (product) {
          this.items = this.items.filter((item) => {
            if (item.id !== product.id) {
              return true;
            } else {
              return false;
            }
          });

          self.dispatchEvent(new Event("cartUpdated"));
          $window.localStorage.setItem(
            "fdCartItems",
            JSON.stringify(this.items)
          );
        };

        this.updateItem = function (item) {
          $window.localStorage.setItem(
            "fdCartItems",
            JSON.stringify(self.items)
          );
        };

        this.getItems = function () {
          if (!this.items) {
            var items = $window.localStorage.getItem("fdCartItems");

            if (items) {
              this.items = JSON.parse(items);

              this.items = this.items.map(function (item) {
                delete item.$$hashKey;
                return item;
              });
            }
          }

          return this.items;
        };

        this.clearSession = function () {
          self.session = {
            hasShippable: false,
            shippingMethod: this.shippingMethods[0],
          };
          $window.localStorage.setItem("fdSession", "{}");
        };

        this.clearCart = function () {
          this.items = [];
          $window.localStorage.setItem("fdCartItems", "[]");
        };

        this.cartIsEmpty = function () {
          return !this.items || this.items.length === 0;
        };

        this.items = this.getItems();

        self.dispatchEvent(new Event("cartUpdated"));

        this.initSession();
      }
    }

    return new Store();
  },
]);
