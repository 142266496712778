angular.module("fonderieComponent").directive("productEditor", [
  "storeService",
  function (Store) {
    return {
      restrict: "E",

      templateUrl: "js/partials/product-editor.html",
      scope: {
        inputTarget: "@",
      },
      link: function ($scope, $element, $attr) {
        var payloadInputField = window.document.getElementById(
          $scope.inputTarget
        );

        $scope.stripeData = {
          metadata: {
            availableQuantity: 0,
          },
        };

        try {
          $scope.stripeData = JSON.parse(payloadInputField.value);

          if (!$scope.stripeData.shipping_rates) {
            $scope.stripeData.shipping_rates = [];
          }

          if (!$scope.stripeData.metadata) {
            $scope.stripeData.metadata = {
              availableQuantity: 0,
            };
          }

          if (Array.isArray($scope.stripeData.metadata)) {
            $scope.stripeData.metadata = {};
          }

          if (!$scope.stripeData.metadata.availableQuantity) {
            $scope.stripeData.metadata.availableQuantity = 0;
          }
        } catch (e) {
          console.log("could not load data", e);
        }

        $scope.$watch(
          "stripeData",
          function (data) {
            payloadInputField.value = JSON.stringify($scope.stripeData);
          },
          true
        );

        $scope.addShippingRate = function () {
          if (!$scope.stripeData.shipping_rates) {
            $scope.stripeData.shipping_rates = [];
          }

          $scope.stripeData.shipping_rates.push({
            name: "",
            stripeId: "",
          });
        };

        $scope.removeShippingRate = function (key) {
          if (!$scope.stripeData.shipping_rates) {
            $scope.stripeData.shipping_rates = [];
          }
        };

        $scope.updateProduct = function () {
          Store.updateProduct$($scope.stripeData);
        };

        $scope.parseProduct = function () {
          Store.getProductByStripePriceId$(
            $scope.stripeData.stripePriceId
          ).then(function (response) {
            $scope.stripeData.id = response.data.id;
            $scope.stripeData.price = response.data.price.unit_amount / 100;
            $scope.stripeData.shippable = response.data.shippable;
            $scope.stripeData.url = response.data.url;

            if (response.data.metadata) {
              $scope.stripeData.metadata = response.data.metadata;
            }
          });
        };
      },
    };
  },
]);
