angular.module("fonderieComponent").directive("showCart", [
  "stripe",
  "storeService",
  function (stripe, Store) {
    return {
      restrict: "E",
      scope: {
        withItems: "@",
      },
      link: function ($scope, $element, $attr) {
        $element.on("click", function () {
          Store.showCart();
        });

        if ($scope.withItems == "true" && Store.cartIsEmpty()) {
          $element.addClass("d-none");
        } else {
          $element.removeClass("d-none");
        }

        Store.addEventListener("cartUpdated", () => {
          if ($scope.withItems == "true" && Store.cartIsEmpty()) {
            $element.addClass("d-none");
          } else {
            $element.removeClass("d-none");
          }
        });

        Store.addEventListener("showCart", function () {
          $element.addClass("cart-visible");
        });

        Store.addEventListener("hideCart", function () {
          $element.removeClass("cart-visible");
        });
      },
    };
  },
]);
